import { eventCountsApi, eventsApi, eventClustersApi, relatedPublishedApi, eventSummaryApi, eventAlertUpdatesApi, eventsDeclusterApi, eventShortlistApi, eventRejectApi, eventHealthInfoApi, eventPublishApi, eventRepublishApi, eventReadApi, onboardingCompletApi, blockedSourcesApi, blockSourceApi, unblockSourceApi, blockedEventApi } from "../apis/index";
import { itemsPerPage } from "../constants/constants";
import { routes } from "../constants/routings";
import fetchApi, { getHeaders, getParams } from "./fetchApi"

export const getEventCounts = (params, authToken) => {
  const headerValue = getHeaders(authToken);
  const paramsDic = getParams(params);
  return fetchApi(eventCountsApi, "GET", {}, headerValue, paramsDic)
    .then(res => {
      return res?.data?.count
    });
}

export const getEvents = (params, authToken) => {
  const headerValue = getHeaders(authToken);
  const paramsDic = getParams(params);
  paramsDic.offset = (params.currentPage - 1) * itemsPerPage;
  paramsDic.size = itemsPerPage;
  paramsDic.sort_type = 0
  return fetchApi(eventsApi, "GET", {}, headerValue, paramsDic)
    .then(res => {
      return res?.data?.events
    }
  );
}

export const getClusterEventsData = (params, authToken) => {
  const headerValue = getHeaders(authToken);
  const paramsDic = {
    "event_type": params.eventType,
    "cluster_id": params.clusterId,
    "event_id": params.eventId
  }
  if (params["eventType"] === routes.published.key && params["subTypes"] && params["subTypes"].length > 0) {
    paramsDic["event_sub_type"] = data.subTypes
  }
  return fetchApi(eventClustersApi, "GET", {}, headerValue, paramsDic)
    .then(res => {
      return res?.data?.clustered_events
    }
  );
}

export const getRelatedPublishedEventsData = (id, parentId, authToken) => {
  const headerValue = getHeaders(authToken);
  const paramsDic = {
    id,
    parent_id: parentId
  }
  return fetchApi(relatedPublishedApi, "GET", {}, headerValue, paramsDic)
    .then(res => {
      return res?.data?.clustered_events
    }
  );
}

export const getEventSummaryData = (eventId, authToken) => {
  const paramsDic = {
    id: eventId,
  }
  const headerValue = getHeaders(authToken)
  return fetchApi(eventSummaryApi, "GET", {}, headerValue, paramsDic)
    .then(res => {
      return res?.data
    }
  );
}

export const getAlertUpdates = (authToken) => {
  const headerValue = getHeaders(authToken)
  return fetchApi(eventAlertUpdatesApi, "GET", {}, headerValue, {})
    .then(res => {
      return res?.data?.updates
    }
  );
}

export const eventAlertChecked = (eventIds, authToken) => {
  const paramsDic = {
    ids: eventIds,
  }
  const headerValue = getHeaders(authToken)
  return fetchApi(eventAlertUpdatesApi, "POST", {}, headerValue, paramsDic)
    .then(res => {
      return res?.data?.summary
    }
  );
}

export const removeEvent = (eventJSON, authToken) => {
  const headerValue = getHeaders(authToken)
  return fetchApi(eventsDeclusterApi, "POST", JSON.stringify(eventJSON), headerValue, {})
    .then(res => {
      return res
    }
  );
}

export const shortlistEvent = (eventJSON, authToken) => {
  const headerValue = getHeaders(authToken)
  return fetchApi(eventShortlistApi, "POST", JSON.stringify(eventJSON), headerValue, {})
    .then(res => {
      return res
    }
  );
}

export function rejectEvent (eventJSON, authToken) {
  const headerValue = getHeaders(authToken)
  return fetchApi(eventRejectApi, "POST", JSON.stringify(eventJSON), headerValue, {})
    .then(res => {
      return res
    }
  );
}

export const saveAsHealthInfo = (eventJSON, authToken) => {
  const headerValue = getHeaders(authToken)
  return fetchApi(eventHealthInfoApi, "POST", JSON.stringify(eventJSON), headerValue, {})
  .then(res => {
    return res
  }
);
}

export const publishEvent = (eventJSON, authToken) => {
  const headerValue = getHeaders(authToken)
  return fetchApi(eventPublishApi, "POST", JSON.stringify(eventJSON), headerValue, {})
    .then(res => {
      return res
    }
  );
}

export const republishEvent = (eventJSON, authToken) => {
  const headerValue = getHeaders(authToken)
  return fetchApi(eventRepublishApi, "POST", JSON.stringify(eventJSON), headerValue, {})
    .then(res => {
      return res
    }
  );
}

export const eventChecked = (eventJSON, authToken) => {
  const headerValue = getHeaders(authToken)
  return fetchApi(eventReadApi, "POST", JSON.stringify(eventJSON), headerValue, {})
    .then(res => {
      return res?.success
    }
  );
}

export const onboardingCompleted = (userId, authToken) => {
  const headerValue = getHeaders(authToken)
  return fetchApi(onboardingCompletApi, "POST", JSON.stringify({ user_id: userId }), headerValue, {})
    .then(res => {
      return res?.success
    }
  );
}

export const getBlockedSources = (authToken) => {
  const headerValue = getHeaders(authToken)
  return fetchApi(blockedSourcesApi, "GET", {}, headerValue, {})
    .then(res => {
      return res?.data
    }
  );
}

export const blockSource = (eventJSON, authToken) => {
  const headerValue = getHeaders(authToken)
  return fetchApi(blockSourceApi, "POST", JSON.stringify(eventJSON), headerValue, {})
    .then(res => {
      return res?.success
    }
  );
}

export const unblockSource = (eventJSON, authToken) => {
  const headerValue = getHeaders(authToken)
  return fetchApi(unblockSourceApi, "POST", JSON.stringify(eventJSON), headerValue, {})
    .then(res => {
      return res?.success
    }
  );
}

export const getBlockedEvent = (eventId, authToken) => {
  const paramDic = {
    event_id: eventId
  }
  const headerValue = getHeaders(authToken)
  return fetchApi(blockedEventApi, "GET", {}, headerValue, paramDic)
    .then(res => {
      return res?.data?.event
    }
  );
}

// ============================

// export function postRemoveEvent (eventJSON, tokenFunc) {
//   var headerValue = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     Authorization: "Bearer " + tokenFunc
//   }
//   return dispatch => {
//     return fetchApi(eventsApiDecluster, "POST", JSON.stringify(eventJSON), headerValue, {}).then(res => { return res?.success });
//   };
// }

// export function postSendEventForApproval (eventJSON, tokenFunc) {
//   var headerValue = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     Authorization: "Bearer " + tokenFunc
//   }
//   return dispatch => {
//     return fetchApi(eventsApi + "send_approval", "POST", JSON.stringify(eventJSON), headerValue, {}).then(res => { return res?.success });
//   };
// }

// export function postUnrejectEvent (eventJSON, tokenFunc) {
//   var headerValue = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     Authorization: "Bearer " + tokenFunc
//   }
//   return dispatch => {
//     return fetchApi(eventsApi + "unreject", "POST", JSON.stringify(eventJSON), headerValue, {}).then(res => { return res?.success });
//   };
// }

// export function getGeneratePDF (eventJSON, tokenFunc) {
//   var headerValue = {
//     Accept: 'application/json',
//     'Content-type': 'application/json',
//     Authorization: "Bearer " + tokenFunc
//   }

//   return dispatch => {
//     return fetchApi(eventsApi + "save_pdf_data", "POST", JSON.stringify(eventJSON), headerValue, {})
//       .then(res => {
//         return res
//       });
//   };
// }

// export function getGuestToken (dashboardType, token) {
//   var paramDic = {
//     dashboard_type: dashboardType
//   }
//   var headerValue = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     Authorization: 'Bearer ' + token,
//   }
//   return dispatch => {
//     return fetchApi(dashboardApi, "GET", {}, headerValue, paramDic)
//     .then(res => {
//       return res
//     });
//   };
// }

// export function getJobId (eventType, startDate, endDate, diseaseCat, stateCat, districtCat, languageCat, sourceCat, eventSubTypeCat, token) {
//   var paramsDic = {
//     event_type: eventType,
//     from_date: moment(startDate).format('yyyy-MM-DDTHH:mm'),
//     to_date: moment(endDate).format('yyyy-MM-DDTHH:mm'),
//   }
//   if (diseaseCat.length > 0) {
//     paramsDic.disease = diseaseCat.toString();
//   }
//   if (stateCat.length > 0) {
//     paramsDic.state = stateCat.toString();
//   }
//   if (districtCat.length > 0) {
//     paramsDic.district = districtCat.toString();
//   }
//   if (languageCat.length > 0) {
//     paramsDic.language = languageCat.toString()
//   }
//   if (sourceCat.length > 0) {
//     paramsDic.source_type = sourceCat.toString();
//   }
//   if (eventSubTypeCat.type && eventSubTypeCat.code !== "0") {
//     paramsDic.event_sub_type = eventSubTypeCat.code.toString();
//   }

//   var headerValue = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     Authorization: 'Bearer ' + token,
//   }
//   return dispatch => {
//     return fetchApi(reportDownloadApi + "create_job", "POST", JSON.stringify(paramsDic), headerValue, {})
//     .then(res => {
//       return res
//     });
//   };
// }

// export function getReportDownload (jobId, token) {
//   var paramDic = {
//     job_id: jobId,
//   }
//   var headerValue = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     Authorization: 'Bearer ' + token,
//   }
//   return dispatch => {
//     return fetchApi(reportDownloadApi + "get_job_data", "GET", {}, headerValue, paramDic)
//     .then(res => {
//       return res
//     });
//   };
// }
