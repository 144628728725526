import React from "react";
import "./Shortlisted.css"
import Card from "../../components/Card/Card";
import useReduxSearch from "../../hooks/useReduxSearch";
import { routes } from "../../constants/routings";
import CardSkeleton from "../../components/Card/Components/Skeleton/CardSkeleton";

const Shortlisted = () => {
    const events = useReduxSearch(routes.shortlisted.key);
    const pagination = useReduxSearch(routes.pagination.key)[routes.shortlisted.key];
    const eventCounts = useReduxSearch(routes.eventCounts.key)[routes.shortlisted.key];
    const subDistricts = useReduxSearch(routes.filters.key).subDistrict;
    return (
         <div className="space-y-2 lg:space-y-3">
            {
                events.isFetching || !subDistricts ? (
                    <div className="w-[91.8%] space-y-3">
                        {new Array(10).fill(0).map((_, idx) => (
                            <CardSkeleton key={idx} />
                        ))}
                    </div>
                ) : (
                    <div className="space-y-4">
                        {events.events.slice(0, 10).map((event, idx) => (
                            <div key={event.id} className="flex-1">
                                <Card
                                    idx={idx + 1}
                                    currentPath={routes.shortlisted.key}
                                    eventCard={event}
                                    totalEvents={eventCounts.count}
                                    currentPage={pagination.currentPage}
                                    mainPage={pagination.currentPage}
                                    showCollapse={true}
                                    showCount={true}
                                />
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    )
};

export default Shortlisted;
